var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-login","cancel-variant":"outline-secondary","ok-title":_vm.forgotPassword ? _vm.$t('Confirm') : _vm.$t('Login'),"cancel-title":_vm.$t('Close'),"centered":"","title":_vm.forgotPassword
      ? _vm.$t('Enter an email to reset your password')
      : _vm.$t('Login Form')},on:{"show":_vm.resetForm,"hidden":_vm.resetForm,"ok":function($event){_vm.forgotPassword ? _vm.resetPassword($event) : _vm.login($event)}}},[(!_vm.forgotPassword)?_c('b-card-text',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2915370960)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false : null,"name":"password","type":"password","placeholder":_vm.$t('Password')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,239528609)})],1)],1)],1),_c('h6',{staticClass:"text-primary font-weight-normal cursor-pointer",on:{"click":function($event){_vm.forgotPassword = true}}},[_vm._v(" "+_vm._s(_vm.$t("Forgot password?"))+" ")])],1):_c('b-card-text',[_c('validation-observer',{ref:"forgotPassword"},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h6',{staticClass:"text-primary font-weight-normal cursor-pointer",on:{"click":function($event){_vm.forgotPassword = false}}},[_vm._v(" "+_vm._s(_vm.$t("Back to login"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }