<template>
  <b-modal
    id="on-review-message"
    centered
    hide-footer
  >
    <div class="col text-center">
      <img
        class="mb-2"
        src="@/assets/images/logo/norskhelse_logo.svg"
        :alt="$t('Norsk Helseportal')"
      >
      <div class="h5 mx-auto mb-2 w-75">
        {{ $t('We are currently checking your profile. Please try to log in later.') }}
      </div>
      <div class="mb-2">
        <b-button
          variant="primary"
          @click="closeModal"
        >
          {{ $t('Okay') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  created() {
    EventBus.$on('showOnReviewModal', () => {
      this.$root.$emit('bv::show::modal', 'on-review-message', '#focusThisOnClose')
    })
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'on-review-message', '#focusThisOnClose')
    },
  },
}
</script>
