<template>
  <b-modal
    id="on-maintenance"
    centered
    hide-footer
    hide-header
    content-class="bg-transparent shadow-none  text-center"
  >
    <img
      src="@/assets/images/pages/maintenance.svg"
      alt=""
      class="w-100"
    >
    <h4 class="text-dark mt-2">
      It seems the website is currently under maintaince. We appologize for this inconvenience
    </h4>
    <b-button
      variant="primary"
      class="mt-1"
      @click="goTo"
    >
      {{ $t('Try again') }}
    </b-button>
  </b-modal>
</template>

<script>

import {
  BModal,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
  },
  mounted() {
    EventBus.$on('showOnMaintenance', () => {
      this.$root.$emit('bv::show::modal', 'on-maintenance', '#focusThisOnClose')
    })
  },
  methods: {
    goTo() {
      this.$router.go()
      this.hideModal()
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'on-maintenance')
    },
  },
}
</script>
