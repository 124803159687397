<template>
  <b-modal
    id="modal-login"
    cancel-variant="outline-secondary"
    :ok-title="forgotPassword ? $t('Confirm') : $t('Login')"
    :cancel-title="$t('Close')"
    centered
    :title="
      forgotPassword
        ? $t('Enter an email to reset your password')
        : $t('Login Form')
    "
    @show="resetForm"
    @hidden="resetForm"
    @ok="forgotPassword ? resetPassword($event) : login($event)"
  >
    <b-card-text v-if="!forgotPassword">
      <validation-observer ref="loginValidation">
        <b-form>
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
                name="email"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Password')"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                :state="errors.length > 0 ? false : null"
                name="password"
                type="password"
                :placeholder="$t('Password')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <h6
        class="text-primary font-weight-normal cursor-pointer"
        @click="forgotPassword = true"
      >
        {{ $t("Forgot password?") }}
      </h6>
    </b-card-text>
    <b-card-text v-else>
      <validation-observer ref="forgotPassword">
        <b-form>
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
                name="email"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <h6
        class="text-primary font-weight-normal cursor-pointer"
        @click="forgotPassword = false"
      >
        {{ $t("Back to login") }}
      </h6>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email, min } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      forgotPassword: false,
      required,
      email,
      min,
    }
  },
  mounted() {
    EventBus.$on('showLoginModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-login', '#focusThisOnClose')
    })
  },
  methods: {
    login(event) {
      event.preventDefault()

      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/login', this.form).then(lastLoginDate => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('You have successfully logged in.'),
                icon: 'LogInIcon',
                variant: 'success',
              },
            })
            this.closeModal()
            if (!lastLoginDate) {
              this.$router.push({ name: 'professional_experience' })
            } else if (!('id' in this.$store.getters['auth/user'].active_business_profile)) {
              this.$router.push('feed')
            }
          })
        }
      })
    },
    resetPassword(event) {
      event.preventDefault()
      this.$refs.forgotPassword.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('auth/forgotPassword', { email: this.form.email })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(
                    'Password reset request sent successfully! To continue, follow the instructions in the email sent to you.',
                  ),
                  icon: 'LogInIcon',
                  variant: 'success',
                },
              })
              this.forgotPassword = false
              this.closeModal()
            })
        }
      })
    },
    closeModal() {
      this.forgotPassword = false
      this.$nextTick(() => {
        this.$bvModal.hide('modal-login')
      })
    },
    resetForm() {
      this.form.email = ''
      this.form.password = ''
    },
  },
}
</script>
