import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n/index'
import auth from '@/router/middlewares/auth'
import onReview from '@/router/middlewares/in_review'
import noCompanyPage from '@/router/middlewares/no_company_page'

export default function useVerticalNavMenu(props, navMenuItemsList) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // isMobile
  // ------------------------------------------------
  const isMobile = computed(() => {
    const currentBreakPoint = store.getters['app/currentBreakPoint']
    return currentBreakPoint === 'sm' || currentBreakPoint === 'xs'
  })
  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (props.isVerticalMenuActive) {
      return isVerticalMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
  }

  const navMenuItems = computed(() => {
    const itemList = navMenuItemsList
    if (store.getters['auth/user'].id) {
      const activeBusinessProfile = store.getters['auth/user'].active_business_profile
      const feedIndex = itemList.findIndex(el => el.route === 'feed')

      if (feedIndex === -1 && !activeBusinessProfile.id) {
        itemList.unshift(
          {
            title: 'Feed',
            route: 'feed',
            icon: 'ServerIcon',
          },
        )
      }

      let pagesIndex = itemList.findIndex(el => el.title === 'Pages')
      if (pagesIndex === -1 && !activeBusinessProfile.id) {
        itemList.splice(2, 0,
          {
            title: 'Pages',
            icon: 'GridIcon',
            children: store.getters['categories/pages'],
            meta: {
              pageTitle: i18n.t('Pages'),
              breadcrumb: [
                {
                  text: i18n.t('Pages'),
                  active: false,
                },
              ],
              middleware: [
                auth,
                onReview,
                noCompanyPage,
              ],
            },
          })
      }
      let videosIndex = itemList.findIndex(el => el.title === 'Videos')
      if (videosIndex === -1 && !activeBusinessProfile.id) {
        itemList.push(
          {
            title: 'Videos',
            route: 'videos',
            icon: 'YoutubeIcon',
          },
        )
      }
      let audiosIndex = itemList.findIndex(el => el.title === 'Audio archive')
      if (audiosIndex === -1 && !activeBusinessProfile.id) {
        itemList.push(
          {
            title: 'Audio archive',
            route: 'audio',
            icon: 'MicIcon',
          },
        )
      }
      if (activeBusinessProfile.id) {
        if (feedIndex !== -1) {
          itemList.splice(feedIndex, 1)
        }
        pagesIndex = itemList.findIndex(el => el.title === 'Pages')
        if (pagesIndex !== -1) {
          itemList.splice(pagesIndex, 1)
        }
        videosIndex = itemList.findIndex(el => el.title === 'Videos')
        if (videosIndex !== -1) {
          itemList.splice(videosIndex, 1)
        }
        audiosIndex = itemList.findIndex(el => el.title === 'Audio archive')
        if (audiosIndex !== -1) {
          itemList.splice(audiosIndex, 1)
        }
        return itemList.map(el => {
          if (el.title === 'My page') {
            return {
              icon: 'UserIcon',
              route: 'feed',
              title: activeBusinessProfile.name,
              children: [
                {
                  title: 'Page feed',
                  route: 'profile_company_proxy',
                  is_child: true,
                },
                {
                  title: 'Page courses',
                  route: 'own_courses',
                  is_child: true,
                },
                {
                  title: 'Page jobs',
                  route: 'my_jobs',
                  is_child: true,
                },
                {
                  title: 'Page products',
                  route: 'my_products',
                  is_child: true,
                },
              ],
            }
          }
          return el
        })
      }
    }
    return itemList
  })

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
    navMenuItems,
    isMobile,
  }
}
