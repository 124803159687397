<template>
  <b-nav-item-dropdown
    ref="changeProfileDropdown"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :src="currentAvatar ? currentAvatar : require('@/assets/images/pages/profile/user-default-avatar.svg')"
      />
      <feather-icon
        size="20"
        icon="ChevronDownIcon"
        class="ml-50 drop-down-arrow"
      />
    </template>
    <h5 class="font-weight-bold p-1 m-0">
      {{ $t('Select account') }}
    </h5>

    <div
      class="position-relative d-flex align-items-center profile-select-radio  cursor-pointer"
      @click.stop="activateUserProfile(user.slug)"
    >
      <div class="change-profile-image-container position-relative d-flex align-items-center justify-content-center">
        <img
          v-if="selected_profile_id !== user.slug"
          class="change-profile-image"
          :src="reloading"
          alt=""
        >
        <b-avatar
          :size="selected_profile_id !== user.slug ? 34 : 42"
          :src="user.profile_avatar ? user.profile_avatar : require('@/assets/images/pages/profile/user-default-avatar.svg')"
        />
      </div>
      <h5 class="dropdown-profile-name font-weight-bold">
        {{ user.name }}
      </h5>
      <b-form-radio
        v-model="selected_profile_id"
        style="z-index: -1"
        class="ml-auto"
        :value="user.slug"
      />
    </div>

    <div
      v-for="profile in profileList"
      :key="profile.slug"
      class="position-relative d-flex align-items-center profile-select-radio cursor-pointer"
      @click.stop="changeProfile(profile.id)"
    >
      <div class="change-profile-image-container position-relative d-flex align-items-center justify-content-center">
        <img
          v-if="selected_profile_id !== profile.id"
          class="change-profile-image"
          :src="reloading"
          alt=""
        >
        <b-avatar
          :size="selected_profile_id !== profile.id ? 34 : 42"
          :src="profile.image ? profile.image : require('@/assets/images/pages/profile/user-default-avatar.svg')"
        />
      </div>
      <h5 class="dropdown-profile-name font-weight-bold">
        {{ profile.name }} - {{ `${$t('Page')}-${$t(`${profile.role}`)}` }}
      </h5>
      <b-form-radio
        v-model="selected_profile_id"
        style="z-index: -1"
        class="ml-auto"
        :value="profile.id"
      />
    </div>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <span class="text-primary">{{ $t('Logout') }}</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, BFormRadio,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import reloading from '@/assets/images/pages/profile-company/reloading.svg'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BFormRadio,
  },
  data() {
    return {
      reloading,
      selected_profile_id: '',
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    activeBusinessProfile() {
      return this.$store.getters['auth/user'].active_business_profile
    },
    profileList() {
      return this.$store.getters['auth/user'].available_business_profiles
    },
    currentAvatar() {
      if (this.activeBusinessProfile.image || this.activeBusinessProfile.image === null) {
        return this.activeBusinessProfile.image
      }
      return this.user.profile_avatar
    },
  },
  created() {
    if (this.activeBusinessProfile.id) {
      this.selected_profile_id = this.activeBusinessProfile.id
    } else {
      this.selected_profile_id = this.user.slug
    }
  },
  methods: {
    hideDropdown() {
      this.$refs.changeProfileDropdown.hide()
    },
    logout() {
      this.$router.push({ name: 'home' }).catch(() => {})
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('You have successfully logged out.'),
          icon: 'LogOutIcon',
          variant: 'success',
        },
      })
      this.$store.dispatch('auth/logout')
      this.hideDropdown()
    },
    async changeProfile(id) {
      const res = await this.$store.dispatch('auth/changeProfile', id)
      if (res.status === 200) {
        this.selected_profile_id = id
        await this.$store.dispatch('auth/getUserInfo')
        this.$router.push(`/profile_company/${this.user.active_business_profile.slug}`)
        this.hideDropdown()
      }
    },
    async activateUserProfile(id) {
      if (this.activeBusinessProfile) {
        const res = await this.$store.dispatch('auth/logOutFromBusinessProfile')
        if (res.status === 204) {
          this.selected_profile_id = id
          this.$store.dispatch('auth/getUserInfo').then(() => {
            this.$router.push('/feed')
          })
        }
        this.hideDropdown()
      }
    },
  },
}
</script>
