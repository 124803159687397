<template>
  <b-modal
    id="no-access-for-company-page-modal"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div class="d-flex w-100 text-center text-black p-2">
      <h4 class="w-100">
        {{ $t('This page is not available for company pages') }}
      </h4>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  created() {
    EventBus.$on('showNoAccessForCompanyPageModal', () => {
      this.$root.$emit('bv::show::modal', 'no-access-for-company-page-modal', '#focusThisOnClose')
    })
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'no-access-for-company-page-modal', '#focusThisOnClose')
    },
  },
}
</script>
