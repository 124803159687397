export default [
  // {
  //   title: 'Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  {
    title: 'Feed',
    route: 'feed',
    icon: 'ServerIcon',
  },
  {
    title: 'My page',
    icon: 'UserIcon',
    children: [
      {
        title: 'My profile',
        route: 'profile_proxy',
        is_child: true,
      },
      {
        title: 'My courses',
        route: 'own_courses',
        is_child: true,
      },
      {
        title: 'My Jobs',
        route: 'my_jobs',
        is_child: true,
      },
      {
        title: 'My Products',
        route: 'my_products',
        is_child: true,
      },
    ],
  },
  {
    title: 'Pages',
    icon: 'GridIcon',
    children: [
      {
        title: 'Pages',
        is_child: true,
      },
    ],
  },
  {
    title: 'Course',
    route: {
      path: '/course/webinares',
    },
    icon: 'LayersIcon',
  },
  {
    title: 'Jobs',
    route: {
      path: '/jobs',
    },
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Marketplace',
    route: 'marketplace',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Videos',
    route: 'videos',
    icon: 'YoutubeIcon',
  },
  {
    title: 'Audio archive',
    route: 'audio',
    icon: 'MicIcon',
  },
]
