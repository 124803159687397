export default {
  methods: {
    async signUp() {
      const payload = await this.$store.dispatch(
        'auth/createIdentificationSession',
      )

      EventBus.$emit('showLoader')

      window.location.href = payload.data.authenticationUrl
    },
  },
}
