<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      v-if="isAuthenticated"
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="21"
        @click="goBack()"
      />
      <b-breadcrumb
        :items="$route.meta.breadcrumb"
      />
    </div>
    <div
      class="ml-auto mr-1"
    >
      <a
        v-if="isNotMobile"
        class="mr-1 info-link"
        href="http://wodo.org/"
        target="_blank"
      >wodo.org</a>
      <a
        v-if="isNotMobile"
        class="mr-1 info-link"
        href="https://www.norskhelseportal.no/about_us"
        target="_blank"
      >About us</a>
      <a
        v-if="isNotMobile"
        class="info-link"
        href="https://www.norskhelseportal.no/next"
        target="_blank"
      >Next</a>
    </div>
    <b-navbar-nav
      v-if="isAuthenticated"
      class="nav align-items-center"
    >
      <Notifications />
      <b-button
        v-if="showCreateButton"
        variant="primary"
        class="mr-2"
        @click="openModal"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Create') }}</span>
      </b-button>
      <select-profile />
      <locale />
    </b-navbar-nav>

    <b-navbar-nav
      v-else
      class="nav align-items-center"
    >
      <b-button
        @click="signUp"
        variant="outline-primary"
        class="mr-2"
      >
        <span class="align-middle">{{ $t('Sign up') }}</span>
      </b-button>
      <b-button
        v-b-modal.modal-login
        variant="primary"
        class="mr-2"
      >
        <span class="align-middle">{{ $t('Login') }}</span>
      </b-button>

      <locale />
    </b-navbar-nav>

    <sign-up />
    <login />
    <registration-required />
    <no-access-for-company-page />
    <create-pop-up />
    <create-workplace />
    <on-maintenance />
    <NoHPRConfirmedMessage id="global" />
    <OnReviewMessage />
  </div>
</template>

<script>
import {
  BButton, BLink, BNavbarNav, BBreadcrumb,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import authMixin from '@/mixins/auth'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Notifications from '@/components/Notifications.vue'
import NoHPRConfirmedMessage from '@/components/modal/NoHPRComfirmedMessage.vue'
import OnReviewMessage from '@/components/modal/OnReviewMessage.vue'
import SignUp from '../../components/auth/SignUp.vue'
import Login from '../../components/auth/Login.vue'
import RegistrationRequired from '../../components/auth/RegistrationRequired.vue'
import NoAccessForCompanyPage from '../../components/modal/NoAccessForCompanyPage.vue'
import CreatePopUp from '../../components/CreatePopUp.vue'
import CreateWorkplace from '../../components/modal/CreateWorkplace.vue'
import OnMaintenance from '../../components/OnMaintenance.vue'
import SelectProfile from '../../components/navbar/SelectProfile.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BBreadcrumb,
    BButton,
    Locale,
    SignUp,
    Login,
    RegistrationRequired,
    NoAccessForCompanyPage,
    NoHPRConfirmedMessage,
    OnReviewMessage,
    CreatePopUp,
    CreateWorkplace,
    OnMaintenance,
    Notifications,
    SelectProfile,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [authMixin],
  computed: {
    isNotMobile() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return currentBreakPoint !== 'sm' && currentBreakPoint !== 'xs'
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    userAvatar() {
      return this.$store.getters['auth/user'].profile_avatar
    },
    userRoles() {
      return this.$store.getters['auth/user'].roles
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompany() {
      return this.user && 'id' in this.user.active_business_profile
    },
    isViewer() {
      return this.representACompany && this.user.active_business_profile.role === 'viewer'
    },
    isHPRApproved() {
      return this.user && this.user.user_info.approved_hrp === 2
    },
    showCreateButton() {
      return (this.representACompany && !this.isViewer) || (!this.isViewer && this.isHPRApproved)
    },
  },
  methods: {
    openModal() {
      EventBus.$emit('showCreateModal')
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
