g<template>
  <b-modal
    id="modal-registratin-required"
    centered
    hide-footer
  >
    <div class="col text-center">
      <img
        class="mb-2"
        src="@/assets/images/logo/norskhelse_logo.svg"
        :alt="$t('Norsk Helseportal')"
      >
      <div class="h5 mx-auto mb-2 w-75">
        {{ $t('Please login or sign up to be able to access content on this page') }}
      </div>
      <div class="mb-2">
        <b-button
          class="mr-2"
          variant="primary"
          @click="logIn"
        >
          {{ $t('Login') }}
        </b-button>
        <b-button
          variant="primary"
          @click="signUp"
        >
          {{ $t('Sign up') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import authMixin from '@/mixins/auth'

export default {
  components: {
    BModal,
    BButton,
  },
  mixins: [authMixin],
  mounted() {
    EventBus.$on('showRegistrationRequiredModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-registratin-required', '#focusThisOnClose')
    })
  },
  methods: {
    logIn() {
      EventBus.$emit('showLoginModal')
      this.hideModal()
    },
    signIn() {
      EventBus.$emit('showSignUpModal')
      this.hideModal()
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-registratin-required')
    },
  },
}
</script>
