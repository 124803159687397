<template>
  <div class="notifications">
    <b-dropdown
      size="sm"
      no-caret
      class="notification-dropdown"
    >
      <template #button-content>
        <div class="notifications-open-button">
          <img
            class="active-dropdown"
            :src="notificationIconActive"
            alt="notifications"
          >
          <img
            class="disabled-dropdown"
            :src="notificationIconDisabled"
            alt="notifications"
          >
          <span
            v-show="unreadCount > 0"
            class="notification-badge bg-primary rounded-circle"
          >{{ unreadCount }}</span>
        </div>
      </template>
      <div class="notifications-header">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="font-weight-bold m-0">
            {{ $t('Updates') }}
          </h4>
          <h6
            class="m-0 text-primary cursor-pointer"
            @click="reviewAllNotifications"
          >
            {{ $t('Mark all as read') }}
          </h6>
        </div>
      </div>
      <div class="notifications-list">
        <notification-item
          v-for="notification in notifications"
          :key="notification.id"
          :is-active="notification.read_at === null"
          :notification="notification"
        />
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import notificationIconActive from '@/assets/images/pages/notification.svg'
import notificationIconDisabled from '@/assets/images/pages/notification-disabled.svg'
import {
  BDropdown,
} from 'bootstrap-vue'
import NotificationItem from '@/components/notifications/NotificationItem.vue'

export default {
  components: {
    BDropdown,
    NotificationItem,
  },
  data() {
    return {
      notificationIconActive,
      notificationIconDisabled,
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['notifications/notifications']
    },
    unreadCount() {
      return this.$store.getters['notifications/unreadCount']
    },
  },
  created() {
    this.fetchNotifications()
  },
  methods: {
    fetchNotifications() {
      this.$store.dispatch('notifications/fetchNotifications')
    },
    async reviewAllNotifications() {
      await this.$store.dispatch('notifications/markNotificationAsRead', [...this.notifications.map(el => el.id)])
      this.$store.dispatch('notifications/fetchNotifications')
    },
  },
}
</script>
