<template>
  <b-modal
    id="modal-sign-up"
    body-class="pt-0"
    centered
    hide-footer
  >
    <b-card-body class="py-0">
      <iframe
        id="signicatIframe"
        ref="signicatIframe"
        width="100%"
        height="100%"
        scrolling="no"
        :src="iframe.src"
        frameborder="0"
      />
    </b-card-body>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BCardBody } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardBody,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      iframe: {
        src: null,
      },
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-sign-up') {
        this.initIframe()
      }
    })

    EventBus.$on('showSignUpModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-sign-up', '#focusThisOnClose')
    })

    window.addEventListener('message', this.iframeListener)
  },
  destroyed() {
    window.removeEventListener('message', this.iframeListener)
  },
  methods: {
    async initIframe() {
      const payload = await this.$store.dispatch(
        'auth/createIdentificationSession',
      )
      this.iframe.src = payload.data.url
    },
    async retrieveSession(sessionId) {
      try {
        const payload = await this.$store.dispatch(
          'auth/retrieveIdentificationSession',
          sessionId,
        )
        this.$router.push({
          name: 'user-verification',
          params: { id: payload.data.id },
        })
      } catch (e) {
        this.interruptRegistration(e.response.data.message)
      }
    },
    iframeListener(event) {
      if (
        event.origin === 'https://id2-test.idfy.io'
        || event.origin === 'https://id.idfy.io'
      ) {
        const data = JSON.parse(event.data)

        switch (
          data.status // => 'success/aborted/error'
        ) {
          case 'success':
            this.retrieveSession(data.sessionId)
            break
          default:
            this.interruptRegistration()
          // this.$refs.signicatIframe.contentWindow.location.reload()
        }
      }
    },
    interruptRegistration(message = null) {
      this.$root.$emit('bv::hide::modal', 'modal-sign-up')
      this.$toast({
        component: ToastificationContent,
        props: {
          title:
            message
            || this.$t(
              'An error occurred while trying to register. Please try again',
            ),
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
iframe {
  min-height: 350px;
}
</style>
