<template>
  <div
    class="w-100"
    @click="reviewNotification"
  >
    <b-dropdown-item
      class="notification-dropdown__item pt-0 w-100"
      href="#"
    >
      <div class="w-100 d-flex flex-row align-items-center">
        <b-avatar
          class="mr-1 active-dropdown"
          size="34"
          variant="primary"
          :src="require('@/assets/images/pages/profile/user-default-avatar.svg')"
        />
        <div class="d-flex text-initial flex-column justify-content-center text-left px-0">
          <h5
            class="mb-0"
            :class="[isActive ? '' : 'text-muted']"
            v-html="makeTextBold(notification.message.body)"
          />
          <h6 class="mb-0 text-muted">
            {{ notification.created_at | transformDate }}
          </h6>
        </div>
      </div>
      <span
        v-show="isActive"
        class="bg-primary notification-indicator rounded-circle"
      />
    </b-dropdown-item>
    <div
      v-if="notification.message.type && (notification.message.type === 'manager_invite' || notification.message.type === 'user_invitation')"
      class="notification-action"
    >
      <span @click="(e) => acceptOrCancel(e, 'accepted')">Accept</span>
      <span @click="(e) => acceptOrCancel(e, 'ignored')">Cancel</span>
    </div>
    <b-dropdown-divider />
  </div>
</template>
<script>
import {
  BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  filters: {
    transformDate(date) {
      return moment(date).format('MMM Do, HH:mm')
    },
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    notification: {
      type: Object,
    },
  },
  data() {
    return {
      moment,
    }
  },
  methods: {
    async reviewNotification() {
      if (this.notification.read_at === null) {
        await this.$store.dispatch('notifications/markNotificationAsRead', [this.notification.id])
        this.$store.dispatch('notifications/fetchNotifications')
      }
      const messageKeys = Object.keys(this.notification.message)
      if (messageKeys.length < 3) return
      if (messageKeys.includes('follower')) {
        this.$router.replace(`/profile/${this.notification.message.user}`)
      } else if (messageKeys.includes('post')) {
        this.$router.replace(`/post/${this.notification.message.post}`)
      } else {
        this.$router.replace(`/profile_company/${this.notification.message.company}`)
      }
    },
    makeTextBold(string) {
      return string.split("'").map((value, index) => (index % 2 === 0 ? value : `<strong>${value}</strong>`)).join('')
    },
    async acceptOrCancel(event, type) {
      event.stopPropagation()

      try {
        const res = await axiosIns.post(this.notification.message.link, {
          acceptance: type,
        })

        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('notifications/fetchNotifications')
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
</script>
