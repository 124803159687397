<template>
  <b-modal
    id="modal-create"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3 class="w-100 mx-auto text-center font-weight-bold">
        {{ $t('Create') }}
      </h3>
    </template>
    <div
      v-if="userPermissions"
      class="col text-center align-items-center "
    >
      <div
        v-if="showButton('COURSE_CREATE')"
        class="row py-2 cursor-pointer create-pop-up-button mb-1 mx-1"
        @click="goTo('/course/new_course')"
      >
        <feather-icon
          size="26"
          icon="LayersIcon"
          class="mx-2 "
        />
        <h4 class="line-height-1 font-weight-bolder m-0">
          {{ $t('Webinar') }}
        </h4>
      </div>
      <div
        v-if="showButton('JOBS_CREATE')"
        class="row py-2 cursor-pointer create-pop-up-button mb-1 mx-1"
        @click="goTo('/jobs/create')"
      >
        <feather-icon
          size="26"
          icon="BriefcaseIcon"
          class="mx-2 "
        />
        <h4 class="line-height-1 font-weight-bolder m-0 ">
          {{ $t('Available job') }}
        </h4>
      </div>
      <div
        v-if="showButton('MARKET_CREATE')"
        class="row py-2 cursor-pointer create-pop-up-button mb-2 mx-1"
        @click="goTo('/marketplace/new')"
      >
        <feather-icon
          size="26"
          icon="PercentIcon"
          class="mx-2 "
        />
        <h4 class="line-height-1 font-weight-bolder m-0">
          {{ $t('For sale') }}
        </h4>
      </div>
      <div
        v-if="!user.companies.data.length && !isEditor"
        class="row py-2 cursor-pointer create-pop-up-button mb-2 mx-1"
        @click="goTo('/profile_company_manage')"
      >
        <feather-icon
          size="26"
          icon="PlusIcon"
          class="mx-2 "
        />
        <h4 class="line-height-1 font-weight-bolder m-0">
          {{ $t('Business profile') }}
        </h4>
      </div>
    </div>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import {
  BModal,
} from 'bootstrap-vue'

import {
  permissions,
} from '@/config/config'

export default {
  components: {
    BModal,
  },
  data() {
    return {
      permissions,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    representACompany() {
      return 'id' in this.user.active_business_profile
    },
    isEditor() {
      return this.representACompany && this.user.active_business_profile.role === 'editor'
    },
    isOwnerOrAdmin() {
      return this.representACompany && (this.user.active_business_profile.role === 'admin' || this.user.active_business_profile.role === 'owner')
    },
  },
  mounted() {
    EventBus.$on('showCreateModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-create', '#focusThisOnClose')
    })
  },
  methods: {
    showButton(permission) {
      return this.isOwnerOrAdmin || this.isEditor || this.checkPermission(permission, this.userPermissions)
    },
    goTo(path) {
      this.$router.push(path)
      this.hideModal()
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-create')
    },
  },
}
</script>
