<template>
  <b-modal
    id="modal-create-workplace"
    centered
    hide-footer
    @show="resetForm"
    @hidden="resetForm"
  >
    <b-card-text>
      <h3 class="text-center font-weight-bolder mt-n2">
        {{ $t('Create workplace') }}
      </h3>
      <validation-observer ref="createWorkplaceValidation">
        <b-form>
          <b-form-group
            :label="$t('Company name')"
            label-for="Company name"
          >
            <validation-provider
              #default="{ errors }"
              name="Company name"
              rules="required"
            >
              <b-form-input
                v-model="form.companyName"
                :state="errors.length > 0 ? false : null"
                name="Company name"
                :placeholder="$t('Company name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Organisation number')"
            label-for="Organisation number"
          >
            <validation-provider
              #default="{ errors }"
              name="Organisation number"
              rules="required"
            >
              <b-form-input
                type="number"
                v-model="form.organization_number"
                :state="errors.length > 0 ? false : null"
                name="Organisation number"
                :placeholder="$t('Organisation number')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('County')"
            :label-for="$t('County')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('County')"
              rules="required"
            >
              <!-- <cleave
                id="time-input"
                v-model="form.orgNumber"
                class="form-control"
                :raw="false"
                :options="options.number"
                :placeholder="$t('Organization number')"
                :state="errors.length > 0 ? false : null"
              /> -->
              <v-select
                v-model="form.county_id"
                class="bg-light"
                label="name"
                :options="counties"
                :reduce="(county) => county.id.toString()"
                :placeholder="$t('County')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Add company logo')"
            :label-for="$t('Add company logo')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Add company logo')"
              rules="required"
            >
              <div class="position-relative">
                <b-form-file
                  v-model="form.image"
                  style="opacity:0"
                  class="w-100 h-100 position-absolute top-0 start-0 cursor-pointer"
                  type="file"
                />
                <img
                  class="w-100"
                  src="@/assets/images/pages/course/file-upload.svg"
                  alt=""
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <img
        v-if="imageLink"
        class="w-25 mt-1"
        :src="imageLink"
        alt=""
      >
      <div class="w-100 text-center mt-2">
        <b-button
          variant="primary"
          @click="createWorkplace"
        >
          Create
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
// import Cleave from 'vue-cleave-component'
import { required, digits } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // Cleave,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      form: {
        companyName: '',
        county_id: '',
        organization_number: null,
        image: null,
      },
      imageLink: '',
      required,
      digits,
      options: {
        number: {
          blocks: [9],
        },
      },
    }
  },
  computed: {
    counties() {
      return this.translate(this.$store.getters['filterData/counties'], this.$i18n.locale)
    },
  },
  watch: {
    // eslint-disable-next-line
    'form.image': {
      handler(val) {
        const reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onloadend = () => { this.imageLink = reader.result }
      },
    },
  },
  mounted() {
    EventBus.$on('showCreateWorkplaceModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-create-workplace', '#focusThisOnClose')
      if (!this.counties.length) {
        this.$store.dispatch('filterData/fetchCounties')
      }
    })
  },
  methods: {
    createWorkplace() {
      this.$refs.createWorkplaceValidation.validate().then(success => {
        if (success) {
          const workplace = new FormData()
          workplace.append('image', this.form.image)
          workplace.append('name', this.form.companyName)
          workplace.append('county_id', this.form.county_id)
          workplace.append('organization_number', this.form.organization_number)
          this.$store.dispatch('workplaces/createWorkplace', workplace).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Workplace created!'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$store.dispatch('workplaces/fetchWorkplaces')
              this.closeModal()
            }
          })
        }
      })
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-create-workplace')
      })
    },
    resetForm() {
      this.form.companyName = ''
      this.form.orgNumber = ''
      this.form.image = null
      this.imageLink = ''
    },
  },
}
</script>
