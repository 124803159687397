var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-create-workplace","centered":"","hide-footer":""},on:{"show":_vm.resetForm,"hidden":_vm.resetForm}},[_c('b-card-text',[_c('h3',{staticClass:"text-center font-weight-bolder mt-n2"},[_vm._v(" "+_vm._s(_vm.$t('Create workplace'))+" ")]),_c('validation-observer',{ref:"createWorkplaceValidation"},[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('Company name'),"label-for":"Company name"}},[_c('validation-provider',{attrs:{"name":"Company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"name":"Company name","placeholder":_vm.$t('Company name')},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Organisation number'),"label-for":"Organisation number"}},[_c('validation-provider',{attrs:{"name":"Organisation number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"name":"Organisation number","placeholder":_vm.$t('Organisation number')},model:{value:(_vm.form.organization_number),callback:function ($$v) {_vm.$set(_vm.form, "organization_number", $$v)},expression:"form.organization_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('County'),"label-for":_vm.$t('County')}},[_c('validation-provider',{attrs:{"name":_vm.$t('County'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"label":"name","options":_vm.counties,"reduce":function (county) { return county.id.toString(); },"placeholder":_vm.$t('County'),"state":errors.length > 0 ? false : null},model:{value:(_vm.form.county_id),callback:function ($$v) {_vm.$set(_vm.form, "county_id", $$v)},expression:"form.county_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Add company logo'),"label-for":_vm.$t('Add company logo')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Add company logo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"position-relative"},[_c('b-form-file',{staticClass:"w-100 h-100 position-absolute top-0 start-0 cursor-pointer",staticStyle:{"opacity":"0"},attrs:{"type":"file"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/pages/course/file-upload.svg"),"alt":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),(_vm.imageLink)?_c('img',{staticClass:"w-25 mt-1",attrs:{"src":_vm.imageLink,"alt":""}}):_vm._e(),_c('div',{staticClass:"w-100 text-center mt-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createWorkplace}},[_vm._v(" Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }